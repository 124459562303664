const crudServiceMixin = {
  methods: {
    post(url, model) {
      return this.$axiosApiMobile.post(url, model);
    },
    delete(url, id) {
      return this.$axiosApiMobile.delete(url + "/" + id);
    },
    get(url, params) {
      return this.$axiosApiMobile.get(url, params);
    },
    getById(url, id) {
      return this.$axiosApiMobile.get(url + "/" + id);
    },
    put(url, model) {
      return this.$axiosApiMobile.put(url, model);
    },
  },
};
export default crudServiceMixin;
